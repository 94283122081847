import React from "react";
import "../App.css";

const TestimonialCard = ({ reviewText, name, designation, rating }) => {
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span
          key={i}
          className={`text-[25px] text-[#ddd] ${
            i <= rating ? "text-[#f5c518]" : ""
          }`}
        >
          &#9733;
        </span>
      );
    }
    return stars;
  };

  return (
    <div className="h-72 p-4 bg-[#F6F6F8] border-none rounded-lg m-5 break-words relative flex flex-col justify-between items-start">
     <div>
     <div className="mb-1 text-left">{renderStars(rating)}</div>
      <p className="review-text text-[16px] text-[#111111] relative text-left before:content-['\201C'] after:content-['\201D'] overflow-hidden text-ellipsis whitespace-normal">
        {reviewText}
      </p>
     </div>
      <div className="text-[14px] text-[#333] text-left mt-2.5">
        <strong>{name}</strong>
        <span className="block text-[12px] text-[#777]">{designation}</span>
      </div>
    </div>
  )
};

export default TestimonialCard;

import React, {useState, useEffect} from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import TermsAndConditionsSection from '../components/Terms_And_Conditions';
import App from '../App';
import Topbar from '../components/SocialMedia_Topbar';
import Header from '../components/Header';
import APIBanner from '../components/API_Banner';
import FEBanner from '../components/FE_Dashboard_Banner';
import BlogList from '../components/Blogs';
import DemoSection from '../components/Demo';
import ContactUs from '../components/Contact_Us';
import AboutUs from '../components/About_Us';
import Footer from '../components/Footer';
import ThankYou from '../components/Thank_You';
import AllTestimonials from '../components/Alltestimonial'


const Layout = ({ children }) => {
    const [isScrolled, setIsScrolled] = useState(false);

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 0);
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);


    return (
        <>
            <Topbar />
            <Header isScrolled={isScrolled} logo={"main"}/>
            {children}
            <Footer />
        </>
    );
};

const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<App /> }/>
                <Route path='/api_automation' element={<Layout><APIBanner /></Layout>} />
                <Route path='/fe_dashboard' element={<Layout><FEBanner /></Layout>} />
                <Route path='/blogs' element={<Layout><BlogList /></Layout>} />
                <Route path='/demo' element={<Layout><DemoSection /></Layout>} />
                <Route path='/contact_us' element={<Layout><ContactUs /></Layout>} />
                <Route path='/about_us' element={<Layout><AboutUs /></Layout>} />
                <Route path='/terms_and_conditions' element={<Layout><TermsAndConditionsSection /></Layout>} />
                <Route path='/thank_you' element={<Layout><ThankYou /></Layout>} />
                <Route path='/testimonials' element={<Layout><AllTestimonials /></Layout>} />

               
            </Routes>
        </Router>
    )
}

export default AppRoutes;

import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import TestimonialCard from "./TestimonialCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { testimonialURL } from "../constants/Constants";
import "../App.css";
import axios from "axios";

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([])
  const [error, setError] = useState(null);

  useEffect(()=>{
    fetchTestimonialData()
  },[])

  const fetchTestimonialData = ()=>{
    axios.get(testimonialURL).then((response) => {
        setTestimonials(response.data); 
      }).catch((err) => {
        setError('Failed to load testimonials.');
        console.error('Error fetching testimonials:', err);
      });
  }

  
  const sliderRef = React.useRef(null);
  const settings = {
    className: "center",
    infinite: true,
    // centerMode: true,
    centerPadding: "60px",
    slidesToShow: 3,
    slidesPerRow: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          // centerMode: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          // centerMode: true,
        },
      },
    ],
  };

  const goToNext = () => sliderRef.current.slickNext();
  const goToPrev = () => sliderRef.current.slickPrev();

  useEffect(() => {
    const handleResize = () => {
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(0);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="max-w-[1250px] mx-auto p-5 text-center relative">
      <h2 className="text-[35px] font-semibold mb-2 text-[#111111]">Testimonials</h2>
     {testimonials.length > 0 ? <><h3 className="text-[20px] font-medium mb-5 text-[#5b5c5f]">
        Don't just take our words, listen theirs
      </h3>
      <div className="relative">
        <button
          className="absolute -left-[50px] md:-left-[30px] top-1/2 transform -translate-y-1/2 bg-gray-100 p-2 text-lg cursor-pointer rounded-full z-10 hover:bg-indigo-800"
          onClick={goToPrev}
        >
          <ChevronLeftIcon className="h-5 w-5 text-white hover:text-white" />
        </button>
        <Slider {...settings} ref={sliderRef}>
          {testimonials.map((review, index) => (
            <TestimonialCard
              key={index}
              reviewText={review.feedbackText}
              name={review.userName}
              designation={review.role}
              rating={review.rating}
            />
          ))}
        </Slider>
        <button
          className="absolute -right-[50px] md:-right-[30px] top-1/2 transform -translate-y-1/2 bg-gray-100 p-2 text-lg cursor-pointer rounded-full z-10 hover:bg-indigo-800"
          onClick={goToNext}
        >
          <ChevronRightIcon className="h-5 w-5 text-white hover:text-white" />
        </button>
      </div>
      <div className="mt-4">
        <a
          href="/testimonials"
          style={{
            color: "#263284",
            textDecoration: "underline",
            fontWeight: "400",
          }}
          className="hover:text-indigo-800"
        > See reviews</a>
      </div></> : (
        <div className="flex flex-col items-center justify-center text-center py-10">
          <svg
            className="w-12 h-12 text-gray-400 mb-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12h6m-6 4h6m-7 4h8m2 0a2 2 0 001-1.732V6.732A2 2 0 0018 5H6a2 2 0 00-1 1.732v13.536A2 2 0 006 21h12z"
            />
          </svg>
          <h2 className="text-lg font-semibold text-gray-700 mb-2">
            No Reviews Found
          </h2>
        </div>
      )}
    </div>
  );
};

export default Testimonials;

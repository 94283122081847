import React, { useEffect, useState } from "react";
import "../App.css";
import ReviewCard from "./TestimonialCard";
import { generateReviews, testimonialURL } from "../constants/Constants";
import axios from "axios";
const Allreview = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [error, setError] = useState(null);
  const allReviews = generateReviews(100);
  const [visibleReviews, setVisibleReviews] = useState(9);
  const [isLoading, setIsLoading] = useState(false);
  const handleLoadMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      setVisibleReviews((prev) => Math.min(prev + 6, testimonials.length));
      setIsLoading(false);
    }, 1000);
  };

  const totalRating = testimonials.reduce((sum, item) => sum + item.rating, 0);
  const averageRating = totalRating / testimonials.length;

  useEffect(() => {
    fetchTestimonialData();
  }, []);

  const fetchTestimonialData = () => {
    axios
      .get(testimonialURL)
      .then((response) => {
        setTestimonials(response.data);
      })
      .catch((err) => {
        setError("Failed to load testimonials.");
        console.error("Error fetching testimonials:", err);
      });
  };

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span
          key={i}
          className={`text-[25px] text-[#ddd] ${
            i <= rating ? "text-[#f5c518]" : ""
          }`}
          style={{ fontSize: "20px" }}
        >
          &#9733;
        </span>
      );
    }
    return stars;
  };
  return (
    <div className="max-w-[1250px] mx-auto p-5 text-center relative">
      <h2 className="text-[35px] font-semibold mb-2 text-[#111111]">
        Testimonials
      </h2>
      {testimonials.length > 0 ? (<><h3 className="text-[20px] font-medium mb-5 text-[#5b5c5f]">
        Don't just take our words, listen theirs
      </h3>
      
        <div className="flex flex-row justify-center items-center gap-[10px]">
          <div className="text-[40px] font-bold text-[#111111]">
            {Math.round(averageRating * 10) / 10}
          </div>
          <div className="leading-none">
            <div>{renderStars(averageRating)}</div>
            <p>{testimonials.length} reviews</p>
          </div>
        </div></>
      ) : (
        <div className="flex flex-col items-center justify-center text-center py-10">
          <svg
            className="w-12 h-12 text-gray-400 mb-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12h6m-6 4h6m-7 4h8m2 0a2 2 0 001-1.732V6.732A2 2 0 0018 5H6a2 2 0 00-1 1.732v13.536A2 2 0 006 21h12z"
            />
          </svg>
          <h2 className="text-lg font-semibold text-gray-700 mb-2">
            No Reviews Found
          </h2>
        </div>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[10px]">
        {testimonials.slice(0, visibleReviews).map((review, index) => (
          <ReviewCard
            key={index}
            reviewText={review.feedbackText}
            name={review.userName}
            designation={review.role}
            rating={review.rating}
          />
        ))}
      </div>
      <div className="text-center mt-[20px]">
        {isLoading && (
          <div className="m-[20px] mx-auto border-[4px] border-[#f3f3f3] border-t-[4px] border-t-[#263284] rounded-full w-[30px] h-[30px] animate-spin"></div>
        )}
        {visibleReviews < testimonials.length && !isLoading && (
          <button
            onClick={handleLoadMore}
            className="mt-[20px] mx-auto py-[5px] px-[20px] bg-[#F6F6F8] text-[#111111] text-[15px] font-medium rounded-full cursor-pointer hover:bg-[#eceaea]"
          >
            Load More
          </button>
        )}
      </div>
    </div>
  );
};

export default Allreview;
